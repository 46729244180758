import { useContext, useEffect, useRef, useState } from "react";

import "Styles/Login.scss"
import { Button, Divider, IconButton, InputAdornment, TextField, styled } from "@mui/material";

// Iconos
import GoogleIcon from '@mui/icons-material/Google';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { login, loginWithGoogle, logout } from "Service/Connections";
import UserContextP from "Interfaces/Context";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { auth } from "Service/Connections";
import Loader from "Components/Loader";

const CSSSearchField = styled(TextField)({
    "& .MuiInputBase-root": {
        color: "#000000",
        borderRadius: "20px",
        backgroundColor: "#dedcdc",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 550,
        "& input": {
            textIndent: "10px",
        },
    },
    '& label.Mui-focused': {
        color: '#173456',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 550,
    },
    '.MuiFormHelperText-root': {
        color:'red',
        fontFamily: "'Poppins', sans-serif",
        backgroundColor: "transparent",
    },
    '& label': {
        color: '#173456',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 550,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            color: '#f1f1f1',
            borderColor: '#f1f1f1',
            borderRadius: "20px"
        },
        '&:hover fieldset': {
            color: '#f1f1f1',
            borderColor: '#f1f1f1',
            borderRadius: "20px"
        },
        '&.Mui-focused fieldset': {
            color: '#f1f1f1',
            borderColor: '#f1f1f1',
            borderRadius: "20px"
        },
    },
});

const Login = () => {
    const refEmail = useRef<any>()
    const refPassword = useRef<any>()
    const context = useContext(UserContextP)
    const navigate = useNavigate()

    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [helperTextEmail, setHelperTextEmail] = useState<string>("")
    const [helperTextPassword, setHelperTextPassword] = useState<string>("")

    useEffect(()=>{
        auth.onAuthStateChanged(async(response) => {
            if ( response !== null ) {
                let name:any = response.displayName?.split(" ")
                context.assignUser(
                    response.uid ?? "",
                    name[1] ?? "",
                    name[0] ?? "",
                    response.email ?? ""
                )
                context.assignateAuth(response.uid ?? "", true)
            }
        })
        // eslint-disable-next-line 
    }, [loader])

    useEffect(()=> {
        if( context.user.email !== "" && context.authenticate.auth ) {
            navigate("/update/order/document")
        }
        // eslint-disable-next-line
    }, [context.user.email, context.authenticate.auth])

    const signInCrendentials = async() => { 
        setHelperTextEmail("")
        setHelperTextPassword("")
        if ( refEmail?.current?.value === "" ) {
            setHelperTextEmail("Ingrese su correo electrónico")
            return
        } 
        
        if ( refPassword?.current?.value === "" ) {
            setHelperTextPassword("Ingrese su contraseña")
            return
        }
        setLoader(true)
        let credentials:any = await login(refEmail?.current?.value, refPassword?.current?.value)
        if ( credentials?.error ) {
            setHelperTextEmail("Correo electrónico o contraseña incorrecta")
            setHelperTextPassword("Correo electrónico o contraseña incorrecta")
            return
        }
        context.assignateAuth(credentials.uid, credentials.auth)
        let body = {
            data: {
                uid: credentials.uid,
                appVersion: "0.6.1",
                platform: "web",
                appId: "-"
            }
        }
        await axios.post(`${process.env.REACT_APP_URL_ORDER}/users/get/accounts/informationacconts`, body).then(async(response) => {
            if ( response.data.data.role !== "admin" &&  response.data.data.role !== "adminsupport" ) {
                await logout()
            } else {
                context.assignUser(response.data.data.$key, response.data.data.last_name, response.data.data.name, response.data.data.email)
                navigate("/update/order/document")
            }
            setLoader(false)
        }).catch((error) => {
            console.log(error.message)
        })
    }

    const signInGoogle = async() => {
        setLoader(true)
        let credentials:any = await loginWithGoogle()

        if ( credentials.error || !credentials.auth ) {
            return
        }
      
        context.assignateAuth(credentials.uid, credentials.auth)
        let body = {
            data: {
                uid: credentials.uid,
                appVersion: "0.6.1",
                platform: "web",
                appId: "-"
            }
        }
        await axios.post(`${process.env.REACT_APP_URL_ORDER}/users/get/accounts/informationacconts`, body).then(async(response) => {
            if ( response.data.data.role !== "admin" &&  response.data.data.role !== "adminsupport" ) {
                await logout()
            } else {
                context.assignUser(response.data.data.$key, response.data.data.last_name, response.data.data.name, response.data.data.email)
                navigate("/update/order/document")
            }
            setLoader(false)
        }).catch(async(error) => {
            setLoader(false)
            await logout()
            console.log(error.message)
        })
    }


    return (
        <div className="root-login">
            <Loader inPage={false} redirect={true} openLoader={loader} />
            <div className="header-login">
                <img src={require("Media/Logo.png")} alt="PremierTools"/>
                <h1>Bienvenido a <span>PremierTools</span></h1>
                <h3>Ingrese sus credenciales</h3>
            </div>
            <div className="content-login">
                <CSSSearchField
                    id="margin-none" 
                    inputRef={refEmail}
                    helperText={helperTextEmail}
                    sx={{ 
                        minWidth: "300px",
                        margin:"auto",
                        justifyContent: "center",
                    }}
                    label="Correo electrónico"
                    placeholder="example@premiertools.app"
                    onChange={() => setHelperTextEmail("")}
                />
                <CSSSearchField 
                    label="Contraseña"
                    inputRef={refPassword}
                    helperText={helperTextPassword}
                    placeholder="pass*******"
                    sx={{ 
                        minWidth: "300px",
                        margin:"auto",
                        justifyContent:"center"
                    }}
                    onChange={() => setHelperTextPassword("")}
                    type={showPassword ? "text" : "password"}
                    id="margin-none" 
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="start">
                                <IconButton onClick={()=> setShowPassword(!showPassword)}>
                                    {
                                        showPassword ?
                                        <VisibilityOffIcon /> :
                                        <VisibilityIcon />
                                    }
                                </IconButton>
                            </InputAdornment>,
                    }}
                />
                <Button
                    className='btn-app-bar'
                    onClick={signInCrendentials}
                >
                    Iniciar sesión
                </Button>
                <Divider>
                    <p>O tambien puedes <br/> iniciar sesion con:</p>
                </Divider>
            </div>
            <div className="footer-login">
                <IconButton aria-label="singin" size="large" onClick={signInGoogle}>
                    <GoogleIcon fontSize="inherit" />
                </IconButton>
                {/* <IconButton aria-label="delete" size="large" onClick={()=> logout()}>
                    <AppleIcon fontSize="inherit" />
                </IconButton> */}
            </div>
        </div>
    )
}

export default Login