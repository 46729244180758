import { useState, useEffect, useContext } from "react";
import {
    Button,
    Divider,
    Grid,
    IconButton
} from "@mui/material";

//Estilo
import "Styles/App.scss"
import UserContextP from "Interfaces/Context";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import axios from "axios";
import Loader from "Components/Loader";
import DialogConfirmation from "Components/DialogConfirmation";
import { CssTextField } from "Components/CssTextField";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";

const DetailDocument: React.FC = () => {
    const context = useContext(UserContextP)
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [order, setOrder] = useState<any>()
    const [showDialog, setshowDialog] = useState(false)
    const [message, setMessage] = useState({
        message: "Peticion ejecutada de forma correcta",
        type: "",
        status: 0,
        data: [],
        title: ""
    })
    const [text, setText] = useState("")
    const [discountDinner, setDiscountDinner] = useState(0)

    useEffect(() => {
        document.title = "Actualizar estado"
        window?.scrollTo(0, 0);
        setOrder(context?.dataCopy?.order)

        if (context?.dataCopy?.order !== undefined && context?.dataCopy?.order?.descuento !== undefined && context?.dataCopy?.order?.total !== undefined) {
            let discountD = context?.dataCopy?.order?.totalLines * Math.round(((context?.dataCopy?.order?.descuento) / 100) * 100) / 100
            discountD = Math.round((discountD) * 100) / 100
            setDiscountDinner(discountD)
        }
        //eslint-disable-next-line
    }, [])

    const changeStatusOrder = async (type: boolean) => {
        let data = {
            token: context.authenticate.uid,
            data: {
                state: type,
                id: context.dataCopy.order.$key,
                notes: text
            }
        }
        setLoader(true)

        await axios.post(`${process.env.REACT_APP_URL_ORDER}/documents/update/pending/status`, data).then((response) => {
            setMessage(response.data)
            setLoader(false)
            setshowDialog(true)

        }).catch((error) => {
            console.log(error.response.data)
            setMessage(error.response.data)
            setLoader(false)

        })
    }

    const assiganteBackgroundColor = () :string =>{
        return context?.dataCopy?.order?.type && context?.dataCopy?.order?.type === 'PurchaseOrder' ? '#79AC78' : '#173456'
    }


    return (
        <div className="App tableUpdateOrder">
            <Loader
                openLoader={loader}
                inPage={false}
                redirect={true}
            />
            <DialogConfirmation
                open={showDialog}
                text={message?.message}
                quantity={-1}
            />
            <div style={{ display:"flex", justifyContent:"space-between" }}>
                <IconButton onClick={()=> navigate(-1)}>
                    <ArrowBackIosIcon />
                </IconButton>
                <h2>Orden número {order?.numberOrder} </h2>
                <div></div>
            </div>
            <Grid container spacing={1} >
                {/* Primera linea del documento  */}
                <Grid item xs={6}>
                    <div className="thBase thHeader" style={{ backgroundColor: assiganteBackgroundColor()}}>
                        <p>
                            Razón social
                        </p>
                    </div>
                </Grid>
                {/* Nombre Comercial */}
                <Grid item xs={6}>
                    <div className="thBase thHeader" style={{ backgroundColor: assiganteBackgroundColor()}}>
                        <p>
                            Nombre Comercial
                        </p>
                    </div>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "-8px" }}>
                    <div className="thBase thContent">
                        <h4>
                            {order?.cardName}
                        </h4>
                    </div>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "-8px" }}>
                    <div className="thBase thContent">
                        <h4>
                            {order?.cardFName}

                        </h4>
                    </div>
                </Grid>
                {/* Direccion de envio  */}
                <Grid item xs={12}>
                    <div className="thBase thHeader" style={{ backgroundColor: assiganteBackgroundColor()}}>
                        <p>
                            Dirección
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "-8px" }}>
                    <div className="thBase thContent">
                        <h4>
                            { order?.street !== '' ? order?.street : 'No posee dirección' }
                        </h4>
                    </div>
                </Grid>

                {/* Listado de productos */}
                <Grid item xs={12}>
                    <div className="thBase thHeader" style={{ backgroundColor: assiganteBackgroundColor()}}>
                        <p>
                            Productos
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "-8px" }}>
                    <div className="thBase thContent" style={{ minHeight: "90px" }}>
                        <Grid item xs={4}>
                            <p>Cantidad</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Descripcion</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Precio unitario <br />sin iva</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Precio de linea<br />con iva</p>
                        </Grid>
                    </div>
                    {
                        order?.products?.map((item: any, index: number) => {
                            return (
                                <div key={index} style={{ borderBottom: "1px solid #00000" }}>
                                    <Grid container spacing={2} style={{ marginTop: "-8px" }}>
                                        <Grid item xs={3}>
                                            <div className="thItemProduct">
                                                <p>{item.cantidad}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="thItemProduct">
                                                <p>{item.ItemName}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="thItemProduct">
                                                <p>${item.price.toFixed(2)}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div className="thItemProduct">
                                                <p>${(item.totalCantidad + item.totalCantidad*0.13).toFixed(2)}</p>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Divider />

                                </div>
                            )
                        })
                    }

                </Grid>
                <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <div className="thBase thHeader" style={{ backgroundColor: assiganteBackgroundColor()}}>
                        <p>
                            Información del documento
                        </p>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>Subtotal</h4>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>${order?.totalLines.toFixed(2)}</h4>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>Porcentaje en descuento</h4>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>{order?.descuento.toFixed(2)}%</h4>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>Descuento en efectivo</h4>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>${discountDinner}</h4>

                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>Total despues del descuento</h4>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>${ (order?.totalLines - discountDinner).toFixed(2) }</h4>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>IVA</h4>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>${(order?.total * 0.13).toFixed(2)}</h4>

                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>Total del documento</h4>
                </Grid>
                <Grid item xs={6}>
                    <h4 style={{ color: "#7f8082" }}>${(parseFloat((order?.total * 0.13).toFixed(2)) + order?.total).toFixed(2)}</h4>
                </Grid>
                <Grid item xs={4}>
                    <div className="thBase thHeader" style={{ backgroundColor: assiganteBackgroundColor()}}>
                        <p style={{ fontSize: "80%", marginTop: "-2px" }}>
                            Tipo de pago
                        </p>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="thBase thHeader" style={{ backgroundColor: assiganteBackgroundColor()}}>
                        <p style={{ fontSize: "80%", marginTop: "-3px" }}>
                            Tipo de 
                            <br/>
                            contribuyente
                        </p>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="thBase thHeader" style={{ backgroundColor: assiganteBackgroundColor()}}>
                        <p style={{ fontSize: "90%", marginTop: "-2px" }}>
                            {
                                 context?.dataCopy?.order?.type && context?.dataCopy?.order?.type === 'PurchaseOrder' ?
                                 'Comprador'
                                 :
                                'Vendedor'
                            }
                        </p>
                    </div>
                </Grid>

                <Grid item xs={4} style={{ marginTop: "-8px" }}>
                    <div className="thBase thContent">
                        <h4>
                            {order?.typePayment}
                        </h4>
                    </div>
                </Grid>

                <Grid item xs={4} style={{ marginTop: "-8px" }}>
                    <div className="thBase thContent">
                        <h4 style={{ whiteSpace: "pre-line", overflowWrap: "break-word" }}>
                            {order?.u_type.split("/").join(" / ")}
                        </h4>
                    </div>
                </Grid>

                <Grid item xs={4} style={{ marginTop: "-8px" }}>
                    <div className="thBase thContent">
                        <h4>
                            {order?.vendorName}
                        </h4>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <CssTextField
                        placeholder="Notas para el vendedor"
                        rows={6}
                        multiline
                        fullWidth
                        value={text}
                        onChange={(e: any) => {
                            setText(e.target.value)
                        }}
                        style={{
                            marginTop: "20px",
                            marginBottom: "10px"
                        }}
                    />

                </Grid>
                <Grid item xs={6}>
                    <Button
                        size="small"
                        fullWidth
                        variant="contained"
                        color="error"
                        endIcon={<CloseIcon sx={{ marginTop: "-4px" }} />}
                        style={{
                            backgroundColor: '#ea3232',
                        }}
                        onClick={() => { changeStatusOrder(false) }}
                    ><p style={{ fontWeight: "600" }}>Rechazar</p></Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        size="small"
                        variant="contained"
                        color="success"
                        fullWidth
                        endIcon={<CheckIcon sx={{ marginTop: "-4px" }} />}
                        style={{
                            backgroundColor: '#32d65f',
                            minHeight: "50px",
                        }}
                        onClick={() => {
                            changeStatusOrder(true)
                        }}
                    ><p style={{ fontWeight: "600" }}>Aprobar</p></Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default DetailDocument;