import React from "react";
import { Authentication, User } from "./User";

export interface UserContextModel {
    user: User;
    authenticate:Authentication;
    dataCopy : any 
    assignateCopyData: (data: any) => void;
    assignateAuth :(id:any, authenticate:boolean)=>void;
    assignUser: (id:string, lastName:string, name:string, email:string)=> void;
    navigateToSegment: (navigate:any) => void
}

const UserContextP = React.createContext<UserContextModel>({
    user: { id:"", name:"", lastName:"", email:""},
    authenticate :{uid:'', auth:false},
    dataCopy:{},
    assignateCopyData: ()=>{},
    assignateAuth : ()=>{},
    assignUser : ()=>{},
    navigateToSegment: ()=>{}
})

export default UserContextP;